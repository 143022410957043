export const validatesite = {
    methods: {
        validatesite () {
            let role = this.$offlineStorage.get("role");
            if (role.service[0].tenant.length > 1 ) {
                this.$router.replace({name: 'selecttenant'});
              } else {
                 this.$offlineStorage.set("tenant",role.service[0].tenant[0]);
                 if (role.service[0].tenant[0].site.length > 1) {
                   this.$router.replace({name: 'selectsite'});
                 } else {
                   this.$offlineStorage.set("site",role.service[0].tenant[0].site[0]);
                   this.$router.replace({name: 'home'});
                 }
              }
        }
    },
}